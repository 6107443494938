import App from '@/App.vue';
import SitchBackButton from '@/components/custom-ui-components/SitchBackButton.vue';
import SitchCurrencyInput from '@/components/custom-ui-components/SitchCurrencyInput.vue';
import SitchDataTableInfo from '@/components/custom-ui-components/SitchDataTableInfo.vue';
import SitchEmailSelect from '@/components/custom-ui-components/SitchEmailSelect.vue';
import SitchTooltip from '@/components/custom-ui-components/SitchTooltip.vue';
import Spacer from '@/components/custom-ui-components/Spacer.vue';
import { productionFirebaseCredentials, stagingFirebaseCredentials } from '@/firebase-credentials';
import vuetify from '@/plugins/vuetify';
import router from '@/router';
import { ping, store } from '@/store';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF, faAppStore, faGoogle, faApple, faGooglePlay, faStripeS } from '@fortawesome/free-brands-svg-icons';
import messages from '@/translations';
import {
  faAddressCard,
  faAnalytics,
  faArchive,
  faArrowAltLeft,
  faArrowCircleDown,
  faArrowCircleUp,
  faArrowDown,
  faArrowLeft,
  faArrowUp,
  faAt,
  faBell,
  faBookOpen,
  faBorderAll,
  faBrowser,
  faCalendarAlt,
  faCalendarPlus,
  faCamera,
  faCaretDown,
  faCartArrowDown,
  faChartNetwork,
  faCheck,
  faChess,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboard,
  faClipboardList,
  faClock,
  faClone,
  faCloudUpload,
  faCode,
  faCog,
  faComments,
  faCopy,
  faDownload,
  faEnvelope,
  faExchange,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileAudio,
  faFileCode,
  faFileExcel,
  faFileImage,
  faFileInvoice,
  faFileInvoiceDollar,
  faFilePdf,
  faFilePowerpoint,
  faFileVideo,
  faFileWord,
  faFolderDownload,
  faFolderOpen,
  faFolderTimes,
  faFolderUpload,
  faGripHorizontal,
  faHandPointUp,
  faHandshake,
  faHandSparkles,
  faImages,
  faLayerGroup,
  faLink,
  faList,
  faLock,
  faMegaphone,
  faMinus,
  faMobileAndroid,
  faMoneyBillWave,
  faMoon,
  faNewspaper,
  faObjectGroup,
  faObjectUngroup,
  faPalette,
  faPaperPlane,
  faPencilAlt,
  faPlug,
  faPlus,
  faPrint,
  faQrcode,
  faQuestion,
  faQuestionCircle,
  faQuestionSquare,
  faRectangleLandscape as faRectangleLandscapeLight,
  faRedo,
  faShareAlt,
  faShoppingCart,
  faSignOut,
  faSmilePlus,
  faSortUp,
  faSparkles,
  faStore,
  faSun,
  faSync,
  faTags,
  faTimes,
  faToggleOff,
  faToggleOn,
  faTrash,
  faTrashRestore,
  faUndo,
  faUnlink,
  faUpload,
  faUser,
  faUserMinus,
  faUserPlus,
  faUsers,
  faVideo,
  faWatch,
  faWifi,
  faWindowMaximize,
  faWrench,
  faTrophy,
  faBox,
  faPowerOff,
  faSignInAlt,
  faStar,
  faTable,  
  faSort,
} from '@fortawesome/pro-light-svg-icons';
import { faCircle as faCircleRegular, faComment, faDotCircle, faHandPaper, faSquare } from '@fortawesome/pro-regular-svg-icons';
import {
  faArrowsAltV,
  faBars,
  faCheckSquare,
  faCircle,
  faLockAlt,
  faTimesCircle,
  faUsdSquare,
  faCaretCircleLeft,
  faCaretCircleRight,
  faCheckCircle,
  faPaperclip,
  faUniversity,
  faEnvelope as faEnvelopeSolid,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { browserLocalPersistence, getAuth, indexedDBLocalPersistence, initializeAuth, setPersistence, User } from 'firebase/auth';
import { Firestore, FirestoreSettings, initializeFirestore, persistentLocalCache } from 'firebase/firestore';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Draggable from 'vuedraggable';
import '../registerServiceWorker';
import { onUserSignIn, signInComplete } from './auth-utils';
import { hideLoading, showLoading } from './loading-utils';
import { showError } from './misc-firestore-utils';
import { processServiceWorkerEvent } from './service-worker-utils';
import { isiOS } from './misc-utils';
import { getStorage } from 'firebase/storage';
import { Capacitor } from '@capacitor/core';
import { StatusBar, Style } from '@capacitor/status-bar';
import { App as CapApp } from '@capacitor/app';

library.add(
  faTimes,
  faSignOut,
  faArrowAltLeft,
  faGripHorizontal,
  faUser,
  faCheck,
  faCopy,
  faTrash,
  faPencilAlt,
  faArrowLeft,
  faFile,
  faLockAlt,
  faAt,
  faQrcode,
  faExclamationCircle,
  faComments,
  faPaperPlane,
  faCloudUpload,
  faMobileAndroid,
  faBars,
  faFileImage,
  faFileAudio,
  faFileVideo,
  faFilePdf,
  faFileWord,
  faFileExcel,
  faFilePowerpoint,
  faFileAlt,
  faFileCode,
  faFileArchive,
  faCopy,
  faTrash,
  faStore,
  faPlus,
  faAddressCard,
  faLink,
  faArchive,
  faHandshake,
  faWindowMaximize,
  faWifi,
  faBrowser,
  faList,
  faLayerGroup,
  faEnvelope,
  faQuestionCircle,
  faRectangleLandscapeLight,
  faCode,
  faCamera,
  faCog,
  faArrowCircleUp,
  faMoneyBillWave,
  faImages,
  faFolderOpen,
  faFolderTimes,
  faChevronRight,
  faQuestion,
  faAnalytics,
  faFileInvoiceDollar,
  faPlug,
  faArrowCircleDown,
  faLock,
  faClipboard,
  faUpload,
  faShoppingCart,
  faPlus,
  faMinus,
  faSync,
  faCalendarAlt,
  faCalendarPlus,
  faChartNetwork,
  faComments,
  faHandPointUp,
  faFolderDownload,
  faFolderUpload,
  faPalette,
  faObjectGroup,
  faObjectUngroup,
  faSmilePlus,
  faTrashRestore,
  faNewspaper,
  faMegaphone,
  faChevronUp,
  faPrint,
  faDownload,
  faBell,
  faClipboardList,
  faSync,
  faRedo,
  faClock,
  faChevronDown,
  faCaretDown,
  faSquare,
  faChevronRight,
  faChevronLeft,
  faCheckSquare,
  faCartArrowDown,
  faCircle,
  faSortUp,
  faArrowsAltV,
  faShareAlt,
  faTimesCircle,
  faStripeS,
  faWrench,
  faFileInvoice,
  faHandPaper,
  faComment,
  faEye,
  faUsdSquare,
  faClone,
  faComments,
  faTags,
  faUserPlus,
  faUserMinus,
  faExchange,
  faSparkles,
  faCircleRegular,
  faDotCircle,
  faUndo,
  faHandSparkles,
  faUsers,
  faUnlink,
  faChess,
  faEyeSlash,
  faArrowUp,
  faArrowDown,
  faWatch,
  faExternalLinkAlt,
  faVideo,
  faCheckSquare,
  faGooglePlay,
  faAppStore,
  faCaretCircleLeft,
  faCaretCircleRight,
  faToggleOff,
  faToggleOn,
  faCheckCircle,
  faMoon,
  faSun,
  faBorderAll,
  faQuestionSquare,
  faBookOpen,
  faTrophy,
  faPaperclip,
  faGoogle,
  faBox,
  faPowerOff,
  faFacebookF,
  faSignInAlt,
  faUniversity,
  faEnvelopeSolid,
  faApple,
  faStar,
  faTable,  
  faSort,
);

export const deepCopy = (object: any) => {
  return JSON.parse(JSON.stringify(object));
};

export const nonDebugLog = (...args: any) => {
  if (!isProductionEnv) {
    console.log(...args);
  }
};

Vue.component('fa', FontAwesomeIcon);
Vue.component('font-awesome-icon', FontAwesomeIcon); // For Vuetify
Vue.component('sitch-email-select', SitchEmailSelect);
Vue.component('sitch-currency-input', SitchCurrencyInput);
Vue.component('sitch-back-button', SitchBackButton);
Vue.component('sitch-tooltip', SitchTooltip);
Vue.component('sitch-data-table-info', SitchDataTableInfo);
Vue.component('spacer', Spacer);
Vue.component('draggable', Draggable);

Vue.config.productionTip = false;

export let rootComponent: any;
export let deferredPrompt: BeforeInstallPromptEvent | null = null;

Vue.use(VueI18n);
export const i18n = new VueI18n({
  locale: 'en',
  messages,
});

window.addEventListener('beforeinstallprompt', (e) => {
  // Prevent Chrome 67 and earlier from automatically showing the prompt
  e.preventDefault();
  // Stash the event so it can be triggered later.
  deferredPrompt = e as BeforeInstallPromptEvent;
});

export const onShowPWAPrompt = () => {
  if (deferredPrompt) {
    deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    deferredPrompt.userChoice.then(() => {
      deferredPrompt = null;
    });
  }
};

export const isProductionEnv = (process.env.NODE_ENV === 'production' && window.location.hostname.includes('mysitch.app')) || Capacitor.isNativePlatform() || true;
export const apiUrl = isProductionEnv ? 'https://sitch-api-dot-sitch-app.appspot.com' : 'https://sitch-test-api-dot-sitch-app-test-64014.uc.r.appspot.com';
// export const apiUrl = isProductionEnv ? 'https://sitch-api-dot-sitch-app.appspot.com' : 'http://localhost:3001';
export const sitchClientUrl = isProductionEnv ? 'https://sitch.app' : process.env.NODE_ENV === 'production' ? 'https://sitch-client-test.web.app' : 'http://localhost:8081';
export const sitchClientUrlNotLocalhost = isProductionEnv ? 'https://sitch.app' : 'https://sitch-client-test.web.app';
export let currFirestore: Firestore;
export let firebaseApp: FirebaseApp;
export const deferredServiceWorkerEvents: any[] = [];

export let gcpApiKey = '';

if (isProductionEnv) {
  const fireStoreSettings: FirestoreSettings = {
    ignoreUndefinedProperties: true,
    localCache: persistentLocalCache(/*settings*/ {}),
  };
  firebaseApp = initializeApp(productionFirebaseCredentials);
  gcpApiKey = productionFirebaseCredentials.apiKey;
  currFirestore = initializeFirestore(firebaseApp, fireStoreSettings);
} else {
  const fireStoreSettings: FirestoreSettings = {
    ignoreUndefinedProperties: false,
    localCache: persistentLocalCache(/*settings*/ {}),
  };
  firebaseApp = initializeApp(stagingFirebaseCredentials);
  gcpApiKey = stagingFirebaseCredentials.apiKey;
  currFirestore = initializeFirestore(firebaseApp, fireStoreSettings);
}

function whichAuth() {
  if (Capacitor.isNativePlatform()) {
    return initializeAuth(firebaseApp, {
      persistence: indexedDBLocalPersistence,
    });
  }
  return getAuth(firebaseApp);
}

export const fbAuth = whichAuth();

export const fbStorage = getStorage();

export const standardApiFetch = (endpoint: string, body: any): Promise<SitchApiResponse> => {
  return new Promise((resolve, reject) => {
    function rejectAndShowError(sitchApiResponse: SitchApiErrorResponse) {
      if (sitchApiResponse.errorMessage) {
        showError(sitchApiResponse.errorMessage);
        reject(sitchApiResponse);
      }
    }
    showLoading();
    fetch(`${apiUrl}/${endpoint}`, {
      method: 'post',
      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      headers: {
        'Content-Type': 'application/json',
      },
      redirect: 'follow', // manual, *follow, error
      referrerPolicy: 'no-referrer', // no-referrer, *client
      body: JSON.stringify(body),
    })
      .then((response) => {
        response
          .json()
          .then((jsonResponse) => {
            if (jsonResponse.error || jsonResponse.errorMessage) {
              rejectAndShowError(jsonResponse);
            } else {
              resolve(jsonResponse);
            }
          })
          .catch((error: any) => {
            rejectAndShowError({ error, errorMessage: `Error getting json from the response from ${endpoint}. ${error?.message || error}` });
          });
      })
      .catch((error: any) => {
        rejectAndShowError({ error, errorMessage: `Something went wrong. ${error?.message || error}` });
      })
      .finally(() => {
        hideLoading();
      });
  });
};

export const initApp = async () => {
  rootComponent = new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h: any) => h(App),
  } as any).$mount('#app').$children[0];

  showLoading();

  if (Capacitor.isNativePlatform()) {
    StatusBar.setStyle({ style: Style.Dark });

    CapApp.addListener('backButton', () => {
      router.go(-1);
    });

    CapApp.getLaunchUrl().then((res: any) => {
      const url = res.url;
      const path = url.split('https://mysitch.app')[1];
      if (path) {
        router.push(path);
      }
    });
  }

  setPersistence(fbAuth, Capacitor.isNativePlatform() ? indexedDBLocalPersistence : browserLocalPersistence)
    .then(() => {
      fbAuth.onAuthStateChanged((user: User | null) => {
        onUserSignIn(user);
      });
    })
    .catch((error: any) => {
      showError(`There was a problem while setting authorization persistance.`, error, true);
      hideLoading();
    });

  if (navigator.serviceWorker) {
    navigator.serviceWorker.onmessage = (event: any) => {
      nonDebugLog('Service worker event received: ', event);
      if (!signInComplete) {
        deferredServiceWorkerEvents.push(event);
      } else {
        processServiceWorkerEvent(event);
      }
    };
  }

  const ua: string = navigator.userAgent.toLowerCase();
  if (isiOS() || (ua.includes('safari') && !ua.includes('chrome'))) {
    const firstPlayFunc = () => {
      // When the audio is ready to play, immediately pause.
      ping.pause();
      ping.removeEventListener('play', firstPlayFunc, false);
    };
    const clickAnywhereFunc = () => {
      // Start playing audio when the user clicks anywhere on the page,
      // to force Mobile Safari to load the audio.
      rootComponent.$el.removeEventListener('touchstart', clickAnywhereFunc, false);
      ping.play();
      window.setTimeout(() => {
        ping.volume = 1;
      }, 1000);
    };
    // To get audio on iOS. Audio work around for safari:
    ping.addEventListener('play', firstPlayFunc, false);
    rootComponent.$el.addEventListener('touchstart', clickAnywhereFunc, false);
  }
};
